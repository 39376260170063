import { marketConstants } from '../../constants';

function marketReducer (state = [], action) {
    switch(action.type) {
        case marketConstants.GET_MARKETS:
            return action.payload;
        
        default:
            return state;
    }
}

export default marketReducer;