import { strategyConstants } from '../../constants';
import indigo from '@material-ui/core/colors/indigo';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';

const strategies = [
    {
        name: 'Scalping',
        disabled: false,
        color: indigo[500],
        colorDisabled: indigo[100],
        sigle: 'S',
    },
    {
        name: 'Suivi de tendances',
        disabled: true,
        color: blue[500],
        colorDisabled: grey[300],
        sigle: 'T',
    }
];

export const getAllStrategies = () => {
    // TODO : get them from server
    return {
        type: strategyConstants.GET_STRATEGIES,
        payload: strategies
    }
}
