import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { label_understood, used_language } from '../../langues';

class AlertDialog extends React.Component {
  static propTypes = {
        text: PropTypes.string.isRequired,
        open: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired
    }
    static defaultProps = {
        open: false,
        text: ''
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

 
    render() {
        const { title, text, open, handleClose } = this.props;
        return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        { label_understood[used_language] }
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
        );
    }
}

export default AlertDialog;
