import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import BarChart from '@material-ui/icons/BarChart';
import Avatar from '@material-ui/core/Avatar';
import { Grid , GridList} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {HEADERS, IndicatorLabels, indicator_headers, trade_headers, used_language} from '../../langues';
import {MarketsAllowed, TRADING_VIEW_URL} from "../../constants"
import {MarketsInfo} from "../Market/marketAction"
import * as _ from 'lodash'
import {Eject, PlayArrow } from "@material-ui/icons";
import {bindActionCreators} from "redux";
import {acceptPositionDetected, cancelPositionDetected} from "./indicatorAction";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import BigNumber from "bignumber.js";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";


const styles = theme => ({

    indicatorCard : {width: "95%", minWidth: "460px", padding: "5px", display: 'block', marginLeft: 'auto', marginRight: 'auto'},
    button: {
        margin: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    card: {
        width: 300,
    },
    cardHeader: {
        paddingBottom: 0
    },
    cardContent: {
        paddingTop:8,
        paddingBottom:0
    },
    alignCenter: {
        textAlign: 'center'
    },
    cardItem: {
        minWidth: 40,
        textAlign: 'center'
    },
    cardItemTitle:
        {
            textAlign: 'center',
            fontSize: 14,
            fontVariant: 'small-caps',
        },
    marginLeft: {
        marginLeft:15
    }
});

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#4CAF50',
            main: '#4CAF50',
            dark: '#388E3C',
            contrastText: '#fff',
        },
        sec: red
    },
    typography: {
        useNextVariants: true
    }
})
function ccyFormat(num) {
    return `${num.toFixed(2)}`;
}
const TAX_RATE = 0.07;


function createRow(id, desc, qty, unit) {

    return { id, desc, qty, unit, price : 0 };
}
const IndicatorTable = (props) => {

    const {indicatorData, isAutoMode, iaStatus, onAcceptClick, classes, onCancelClick} = props
    const isDisabled = !(indicatorData.isActive  && iaStatus)
    const {isTradable, isInProgress} = indicatorData

    const internalClass = {

        head: {
            backgroundColor: 'black'
        },
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
            overflowX: 'auto',
        },
        table: {
            minWidth: 700,
        },
    }


    // const pairProfitColor =
    //     indicatorData.pairProfit === '-'
    //         ? '-' : indicatorData.pairProfit.gt(0)
    //         ? 'green' : 'red'




    const isActive = !isDisabled && !_.isNil(indicatorData.pairProfit)


    const profitColor = !isActive ? 'black'
        : new BigNumber(indicatorData.pairProfit).gt(0) ? 'green'
            : 'red'
    const probabilityColor = !isActive ? 'black'
        : new BigNumber(indicatorData.resultIaData.probability).gt(0.5) ? 'green'
            : 'red'


    console.log(indicatorData.pairProfit)
    
    let currencies = []
    return (

        <Table>

            {/*<TextField*/}
            {/*    id="outlined-select-currency"*/}
            {/*    select*/}
            {/*    label="Select"*/}
            {/*    className={classes.textField}*/}
            {/*    value={this.state.currency}*/}
            {/*    onChange={this.handleChange('currency')}*/}
            {/*    SelectProps={{*/}
            {/*        MenuProps: {*/}
            {/*            className: classes.menu,*/}
            {/*        },*/}
            {/*    }}*/}
            {/*    helperText="Please select your currency"*/}
            {/*    margin="normal"*/}
            {/*    variant="outlined"*/}
            {/*>*/}
            {/*    {currencies.map(option => (*/}
            {/*        <MenuItem key={option.value} value={option.value}>*/}
            {/*            {option.label}*/}
            {/*        </MenuItem>*/}
            {/*    ))}*/}
            {/*</TextField>*/}
            <TableHead>
                <TableRow>
                    <TableCell align="center">
                        Start Movements
                    </TableCell>
                    <TableCell align="center">
                        Start Detection
                    </TableCell>
                    <TableCell align="center">
                        Side {_.isNil(indicatorData.mainCode) ? '- / - ' : indicatorData.mainCode + ' / ' + indicatorData.subCode}
                    </TableCell>
                    <TableCell align="center">
                        Max / Min
                    </TableCell>
                    <TableCell align="center">
                        Pair Ratio
                    </TableCell>
                    <TableCell align="center">
                        Pair Profit
                    </TableCell>

                    <TableCell align="center">
                        IA Probability
                    </TableCell>

                </TableRow>

            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell align="center" style={{fontSize: '13px', fontWeight:"bold"}} >
                        {
                            isActive
                                ? moment.unix(indicatorData.startMovementTimestamp).format('YYYY.MM.DD HH:mm:ss.SSS')
                                : "-"

                        }
                    </TableCell>
                    <TableCell align="center" style={{fontSize: '13px', fontWeight:"bold"}}>
                        {
                            isTradable || isInProgress
                                ? moment.unix(indicatorData.openRequestTimestampMilli).format('YYYY.MM.DD HH:mm:ss.SSS')
                                : "-"

                        }
                    </TableCell>
                    <TableCell align="center">
                        {
                            isTradable || isInProgress
                                ? indicatorData.mainSide + ' / ' + indicatorData.subSide
                                : "-"
                        }

                    </TableCell>
                    <TableCell align="center"  >
                        <p style={{width: '230%'}}>
                            {
                                isTradable || isInProgress
                                    ?
                                    new BigNumber(indicatorData.maxPairValue).toFormat(4) + ' $'
                                    : "-"

                            }
                        </p>

                        <p style={{width: '230%'}}>
                            {
                                isTradable || isInProgress
                                    ?
                                    new BigNumber(indicatorData.minPairValue).toFormat(4) + ' $'
                                    : "-"

                            }
                        </p>
                    </TableCell>
                    <TableCell
                        align="center">
                        {
                            isActive
                                ? new BigNumber(indicatorData.pairRatio).multipliedBy(100). toFormat(4)  + ' %'
                                : "-"

                        }
                    </TableCell>
                    <TableCell
                        align="center"
                        style={{color: profitColor }}>
                        {
                            isTradable || isInProgress
                                ? new BigNumber(indicatorData.pairProfit).toFormat(4)  + '$'
                                : "-"

                        }
                    </TableCell>

                    <TableCell
                        align="center"
                        style={{color: probabilityColor }}>
                        {
                            isActive
                                ? new BigNumber(indicatorData.resultIaData.probability).multipliedBy(100).toFormat(4)  + '%'
                                : "-"
                        }
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell rowSpan={2} colSpan={4} align="left"/>

                    <TableCell align="right">
                        <Button variant="contained"  onClick={onAcceptClick} className={classes.buttonAccept} disabled={!isTradable || isAutoMode} >
                            {<PlayArrow />} {IndicatorLabels[used_language][0]}
                        </Button>
                    </TableCell>

                    <TableCell colSpan={1}>
                        <Button variant="contained"  onClick={onCancelClick} className={classes.buttonCancel} disabled={!isTradable || isAutoMode}>
                            {<Eject />} {IndicatorLabels[used_language][1]}
                        </Button>
                    </TableCell>
                </TableRow>

            </TableBody>
        </Table>

    )}

const CardContentWrapper = (props) => {
    const {indicatorData, classes, isAutoMode, iaStatus, onAcceptClick, onCancelClick} = props

    const indicatorDataIsNil = _.isNil(indicatorData)


    return  (<CardContent className={classes.cardContent}>

        {indicatorDataIsNil ? null :
            <IndicatorTable {...props}/>
            // <Grid
            //     container
            //     direction="column"
            //     justify="space-around"
            //     alignItems="stretch"
            //
            //     xs={12}
            //     spacing={0}>
            //     <Grid item xs={4}>
            //         <Grid >
            //
            //             <Grid item className={classes.cardItemTitle}>
            //                 {indicator_headers[used_language][HEADERS.PAIR_PROFIT]}
            //             </Grid>
            //
            //
            //
            //             {/*<Grid item className={[classes.cardItemTitle, classes.marginLeft].join(" ")}>*/}
            //             {/*    {indicator_headers[used_language][HEADERS.ACCURATE]}*/}
            //             {/*</Grid>*/}
            //
            //             {/*<Grid item className={[classes.cardItemTitle, classes.marginLeft].join(" ")}>*/}
            //             {/*    {indicator_headers[used_language][HEADERS.BALANCE]}*/}
            //             {/*</Grid>*/}
            //         </Grid>
            //     </Grid>
            //
            //     <Grid item xs={8}>
            //
            //         <Grid container direction="row"
            //               justify="space-between"
            //               alignItems="center" xs={12}>
            //
            //             <Grid item className={classes.cardItem} xs={4}>
            //                 {
            //                     !_.isNil(indicatorData.pairProfit)
            //                     && !isDisabled
            //                         ? indicatorData.pairProfit
            //                         : "-"
            //
            //                 }
            //             </Grid>
            //
            //             <Grid item className={classes.cardItem} xs={4}>
            //                 <Button variant="contained"  onClick={onAcceptClick} className={classes.buttonAccept} disabled={isDisabled}>
            //                     {<PlayArrow />} {IndicatorLabels[used_language][0]}
            //                 </Button>
            //
            //             </Grid>
            //
            //             <Grid item className={classes.cardItem} xs={4}>
            //                 <Button variant="contained"  onClick={onCancelClick} className={classes.buttonCancel} disabled={isDisabled}>
            //                     {<Eject />} {IndicatorLabels[used_language][1]}
            //                 </Button>
            //             </Grid>
            //
            //         </Grid>
            //
            //     </Grid>
            //
            //
            // </Grid>
        }


    </CardContent>)
}


class IndicatorPresentational extends React.Component
{
    constructor(props) {
        super(props)
        this.openWindowChart = this.openWindowChart.bind(this)

        this.state = { chartOpen : false, charts : [] }


    }
    openWindowChart(link) {

// RTODO May not work
        window.open(TRADING_VIEW_URL, "Robankhood : Ana ", "width=1200,height=640")

    }

    render() {
        const {classes,
            indicatorState,
            isAutoMode,
            iaStatus,
            acceptPositionDetected, cancelPositionDetected} = this.props;

        return (
            <div>
                <MuiThemeProvider theme={theme}>
                    {
                        MarketsAllowed.map((marketName, index) => {

                                // Ca devrait etre initialiser par le serveur
                                const marketInfo = MarketsInfo.find(x => x.code === marketName );
                                const indicatorData = indicatorState.find(id => id.code === marketName)

                                return (
                                    <div key={index}>


                                        <Card className={classes.indicatorCard} >
                                            <CardHeader
                                                className={classes.cardHeader}
                                                style={{"marginBottom" : "10px"}}
                                                avatar={
                                                    <Avatar aria-label={marketInfo.long} style={{backgroundColor: marketInfo.color}}>
                                                        {marketInfo.sigle}
                                                    </Avatar>
                                                }
                                                action={
                                                    <IconButton disabled={true} style={{color: 'black'}} onClick={(link) => this.openWindowChart(link)}>
                                                        <BarChart/>
                                                    </IconButton>
                                                }
                                                title={marketName}
                                            />

                                            <CardContentWrapper indicatorData={indicatorData}
                                                                onAcceptClick={() => acceptPositionDetected(indicatorData)}
                                                                onCancelClick={() => cancelPositionDetected(indicatorData)}
                                                                classes={classes}
                                                                iaStatus={iaStatus}
                                                                isAutoMode={isAutoMode}/>
                                        </Card>


                                    </div>

                                )
                            }
                        )}
                </MuiThemeProvider>
            </div>
        );
    }

}

function mapStateToProps({ indicatorState, ia }) {

    return { indicatorState, isAutoMode : ia.isAutoMode, iaStatus : ia.iaStatus  };

}

function mapDispatchToProps(dispatch){

    return bindActionCreators({acceptPositionDetected, cancelPositionDetected}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IndicatorPresentational));


