import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import CardActionArea from '@material-ui/core/CardActionArea';
import grey from '@material-ui/core/colors/grey';

const styles = theme => ({
    root: {
      flexGrow: 1
    },
    control: {
      padding: theme.spacing.unit * 2
    },
    card: {
        backgroundColor: grey[50]
    },
    title: {
        fontSize: 20
    },
    minMax: {
        'min-width': 380,
        margin:20
    }
});


StrategyPresentationnal.propTypes = {
    strategies: PropTypes.array.isRequired,
    handleAddRemoveStrategy: PropTypes.func.isRequired
}

function StrategyPresentationnal(props) {
    const { classes, strategies, handleAddRemoveStrategy } = props; 
    return (
        <Grid container className={classes.root} spacing={16}>
            <Grid item xs={12}>
                <Grid container className={classes.demo} justify="flex-start" alignItems="flex-start" spacing={40}>
                {strategies.map(strategy => (
                    <Grid key={strategy.name} item xs={3} className={classes.minMax}>
                        <Card style={{backgroundColor: (strategy.disabled ? grey[50] : "#FFF")}}>
                            <CardActionArea onClick={() => handleAddRemoveStrategy(strategy)}>
                            <CardHeader
                            classes={{
                                title: classes.title,
                                }}
                            avatar={
                                <Avatar aria-label={strategy.name} style={{backgroundColor: (strategy.disabled ? strategy.colorDisabled : strategy.color)}}>
                                    {strategy.sigle}
                                </Avatar>
                            }
                            title={strategy.name}
                        />
                        
                        </CardActionArea>
                        </Card>     
                    </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(StrategyPresentationnal);