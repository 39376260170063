import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import history from '../../routes/history';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import BarChart from '@material-ui/icons/BarChart';
import Business from '@material-ui/icons/Business';
import Person from '@material-ui/icons/Person';
import Layers from '@material-ui/icons/Layers';
import CenterFocus from '@material-ui/icons/CenterFocusStrong';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { used_language, menu_labels, no_notif_label, label_opened_notif, label_closed_notif } from '../../langues';
import { routes_menu, MAX_NOTIF_HUNG } from '../../constants';
import Grid from '@material-ui/core/Grid';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeAllNotifsSeen } from '../../components/Notification/notificationAction';
import { MarketsInfo } from '../../components/Market/marketAction';
import Avatar from '@material-ui/core/Avatar';
import { notificationConstants, company_name } from '../../constants';
import { trade_ticks_and_prices } from '../../constants';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import blueGrey from '@material-ui/core/colors/blueGrey';
import lightGreen from '@material-ui/core/colors/lightGreen';
import red from '@material-ui/core/colors/red';
import Button from "@material-ui/core/Button"
import 'status-indicator/styles.css'
import {Icon} from "@material-ui/core"
import StatusIndicator from "./StatusIndicator/StatusIndicator"



const styles = {
  root: {
    display: 'flex',
  },
  list: {
    width: 250,
  },
  link: {
    textDecoration: 'none'
  },
  title: {
    'text-align': 'center'
  },
  grow: {
    fontFamily : 'Dense',
    fontSize: 30
  },
  listItem: {
    'min-width': 250
  },
  price: {
    color: blueGrey[900]
  },
  open: {
    color: lightGreen[500]
  },
  close: {
    color: red[500]
  }
};

class MenuDrawer extends Component
{
  state =
      {
        open: false,
        left: false
      };

  static propTypes =
      {
        classes: PropTypes.object.isRequired
      };

  toggleDrawer = (open) => () =>
  {
    this.setState({
      left: open
    });
  };

  handleToggle = () =>
  {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event =>
  {
    this.props.makeAllNotifsSeen();
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  handleClickNotif = event =>
  {
    history.push(routes_menu[0]);
    this.handleClose(event);
  }

  render() {
    const { classes } = this.props;

    const icons = [<Business />, <Layers />, <CenterFocus />, <BarChart />, <Person />]

    const labels_and_routes = menu_labels[used_language].map( function (a, i) {
      return [a, routes_menu[i]];
    })

    const sideList = (
        <div className={classes.list}>
          <List>
            {labels_and_routes.map((text, index) => (
                <Link to={text[1]} className={classes.link} key={text[0]}>
                  <ListItem button>
                    <ListItemIcon>
                      <Typography>{icons[index]}
                      </Typography>
                    </ListItemIcon>
                    <ListItemText primary={text[0]} />
                  </ListItem>
                  <Divider />
                </Link>
            ))}
          </List>
        </div>
    );

    const { notifs } = this.props
    const { open } = this.state;
    const nb_notifs_unseen = notifs.filter(x => !x.seen).length

    return (
        <div className={classes.root}>
          <AppBar position="static">
            <Toolbar>

              {/*Full line */}
              <Grid container direction="row" justify="space-between" >

                {/* Grid :  Drawer Button + Company name*/}
                <Grid item>
                  <Grid container direction="row" justify="flex-start">
                    <Grid item>
                      <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.toggleDrawer(true)} >
                        <MenuIcon />
                      </IconButton>
                    </Grid>

                    <Grid item >
                      <Typography variant="h6" color="inherit" className={classes.grow}>
                        { company_name }
                      </Typography>
                    </Grid>

                  </Grid>
                </Grid>

                {/* Grid : Websocket state + Notification */}
                <Grid item >
                  <Grid container direction="row" justify="flex-end" alignItems={"center"}>
                    <Grid item>
                      <IconButton
                          color="inherit"
                          buttonRef={node => {this.anchorEl = node;}}
                          onClick={this.handleToggle}>
                        {nb_notifs_unseen > 0 ?
                            <Badge badgeContent={nb_notifs_unseen} color="secondary">
                              <NotificationsIcon />
                            </Badge>
                            :
                            <NotificationsIcon />}
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <StatusIndicator socketState={this.props.socketState}/>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Notification opened */}
                <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                      <Grow
                          {...TransitionProps}
                          id="menu-list-grow"
                          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={this.handleClose}>
                            <MenuList>
                              {notifs.length > 0 ?
                                  notifs.slice(0, MAX_NOTIF_HUNG).map((notif, i) => {
                                    const market = MarketsInfo.find(x => x.code === notif.market);
                                    return (
                                        <ListItem button divider key={i} onClick={this.handleClickNotif} className={classes.listItem} >
                                          <Avatar aria-label={market.long} style={{backgroundColor: market.color}}>
                                            {market.sigle}
                                          </Avatar>
                                          <ListItemText
                                              classes={{ primary: notif.type === notificationConstants.CLOSED_TRADE ? classes.close : classes.open }}
                                              primary={notif.type === notificationConstants.CLOSED_TRADE ? label_closed_notif[used_language] : label_opened_notif[used_language]}
                                              secondary={notif.date} />
                                          <ListItemSecondaryAction className={classes.price} >
                                            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: trade_ticks_and_prices[market.code].currency }).format(notif.price)}
                                          </ListItemSecondaryAction>
                                        </ListItem>
                                    )})
                                  :
                                  <MenuItem>{no_notif_label[used_language]}</MenuItem>
                              }
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                  )}
                </Popper>
              </Grid>
            </Toolbar>
          </AppBar>

          <Drawer open={this.state.left} onClose={this.toggleDrawer(false)}>
            <div
                tabIndex={0}
                role="button"
                onClick={this.toggleDrawer(false)}
                onKeyDown={this.toggleDrawer(false)}>

              <img src="/assets/images/menu_header.jpg" alt="Robank Hood"/>
              { sideList }
            </div>
          </Drawer>

        </div>
    );
  }
}

function mapStateToProps({ notifs, userState })
{
  return { notifs, socketState: userState.socketState };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ makeAllNotifsSeen }, dispatch);
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MenuDrawer)));
