import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { company_name } from '../../constants';

const styles = {
  list: {
    width: 250,
  },
  link: {
    textDecoration: 'none'
  },
  title: {
    'text-align': 'center'
  },
  grow: {
    fontFamily : 'Dense',
    fontSize: 30
  }
};

class StaticAppBar extends Component {
  state = {
    left: false
  };

  render() {

    const { classes } = this.props;
    
    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.grow}>
                { company_name }
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withStyles(styles)(StaticAppBar);