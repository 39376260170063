import { notificationConstants } from '../../constants';

export const addNotification = (type, data) => {
    return {
        type: type,
        payload: data
    }
}

export const makeAllNotifsSeen = () => {
    return {
        type: notificationConstants.MAKE_ALL_SEEN
    }
}