import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import Divider from '@material-ui/core/Divider';
import grey from '@material-ui/core/colors/grey';


const styles = theme => ({
    root: {
      flexGrow: 1
    },
    control: {
      padding: theme.spacing.unit * 2
    },
    title: {
       fontSize: 20,
       fontWeigth: 'bold'
    },
    card: {
        backgroundColor: grey[50]
    },
    minMax: {
        'min-width': 400,
        margin: 20
    }
});


MarketPresentationnal.propTypes = {
    markets: PropTypes.array.isRequired,
    handleAddRemoveFromWallet: PropTypes.func.isRequired
}

function MarketPresentationnal(props) {
    const { classes, markets, handleAddRemoveFromWallet } = props; 
    return (
        <Grid container className={classes.root} spacing={16}>
          <Grid item xs={12}>
            <Grid container justify="flex-start" alignItems="flex-start" spacing={40}>
              {markets.map(market => (
                <Grid key={market.short} item xs={3} className={classes.minMax}>
                  <Card style={{backgroundColor: (market.disabled ? grey[50] : "#FFF")}}>
                  <CardActionArea onClick={() => handleAddRemoveFromWallet(market)}>
                      <CardHeader
                        classes={{
                            title: classes.title,
                            }}
                        avatar={
                            <Avatar aria-label={market.long} style={{backgroundColor: (market.disabled ? market.colorDisabled : market.color)}}>
                                {market.sigle}
                            </Avatar>
                        }
                        title={market.long}
                        subheader={market.short}
                    />
                    <Divider/>
                    <CardActions>
                        <Typography component="p">
                            {market.foot}
                        </Typography>
                    </CardActions>
                    </CardActionArea>
                  </Card>     
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
    );
}

export default withStyles(styles)(MarketPresentationnal);