import { strategyConstants } from '../../constants';

function strategyReducer (state = [], action) {
    switch(action.type) {
        case strategyConstants.GET_STRATEGIES:
            return action.payload;
        
        default:
            return state;
    }
}

export default strategyReducer;